@import "../../const.scss";
@import "../../general.scss";

.cares{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.patientSelection{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
}

.careTable{
    width: 100%;
}