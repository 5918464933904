@import "../../const.scss";
@import "../../general.scss";


.calendar {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.MuiSvgIcon-root.printIconSvg {
  width: 40px;
  height: 40px;
  color: $pink;
}

.printIcon .MuiIconButton-label {
  color: $pink;
  .printText {
    margin-left: 5pt;
  }
}

.calendarContainer {
  display: flex;
  flex-wrap: wrap;
  padding: 2%;

  .space {
    padding-left: 5%;
  }

  p {
    margin-top: auto;
    margin-bottom: auto;
  }

  .MuiAccordionSummary-content {
    margin: 0 !important;
  }

  .columnContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    flex: 1 1 0;

    table {
      border-spacing: 2px;
    }

    #deleteButton {
      padding: 10 px 0 0 0;
      font-size: 1.3rem;
    }

    td p {
      text-align: center;
      margin: inherit;
    }

    img {
      vertical-align: middle;
      align-self: center;
    }

    .mobileSpace {
      height: 1rem;
      width: 100%;
    }

    .column {
      min-width: 10%;
      border-right: 1px solid #cce5ff;
      padding: 0 5px 0 5px;
      flex-grow: 1;

      .MuiAccordionSummary-root {
        color: #69cbe4;
        background-color: #6b6f8f21;
        padding: 2% 0 2% 22pt;
      }

      .MuiTypography-root {
        width: 100%;
      }

      .columnHeader {
        align-items: center;
        display: flex;
      }

      h2 {
        padding-left: 22pt;
        margin: 0;
        display: flex;
        align-items: center;

        img {
          margin-right: 10pt;
        }
      }

      .mobileTotalCares {
        color: $orange;
        font-size: 22pt;
      }

      table {
        margin: 10px 0px 10px 0px;
        border-bottom: 1px solid #93c4f8;
      }

      td {
        border-bottom: none;
        padding: 6px 2px 6px 2px;
        vertical-align: baseline;
      }
    }
  }
}

.columnContainer .MuiInputBase-input {
  font-size: small;
  padding: 8px 0 0 0;
}
.columnContainer .MuiInput-formControl {
  margin-top: 15px;
}

.careName {
  margin-bottom: 0;
}

.totalCares {
  color: #ffd1b2;
  text-align: center;

  &.isBig {
    font-size: 25pt;
  }

  .totalCaresBigNumber {
    font-size: 55pt;
  }
}

.noteContainer {
  margin: 2%;
  padding: 2%;
  border: 1px dotted $dark-purple;

  h2 {
    color: $dark-purple;
  }

  .addNote {
    background-color: $blue;
    color: white;
    font-family: "Segoe UI";
    text-transform: none;
  }
  .addNote:hover {
    background-color: $dark-purple;
  }
  .addNote:focus {
    border: none;
    outline: none;
  }
}

.patientName {
  color: $dark-purple !important;
}

.printable { 
  .MuiTableCell-root {
    font-size: x-small !important;
  }
}


.patientCaresTable {
  font-family: "Segoe UI";
}
