@import "const.scss";

body {
  margin: 0;
  font-family: "Segoe UI";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $background-color;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.flex {
  display: flex;
  flex-wrap: wrap;
}

.flexImg {
  place-self: flex-start;
}

h1 {
  text-transform: capitalize;
  text-align: center;
  font-size: 50px;
}

.defaultContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 20%;
  width: 80%;
}

.verticalSpace {
  padding-top: 5%;
}
