@import '../../const.scss';
@import '../CalendarPage/CalendarPage.scss';
@import "../../general.scss";



.calendarContainer > .columnContainer > .personalColumn {
    min-width: 20%;
   
}


.personalColumn  table{
    border-spacing: 2px;
    margin: 10px 0px 10px 0px;
}

.columnContainer .MuiInputBase-input{
    font-size: small;
    padding: 8px 0 0 0;
}
.columnContainer .MuiInput-formControl{
    margin-top: 15px;
}



.selectNurse {
    margin: auto;
}