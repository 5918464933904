@import "../../const.scss";
@import "../../general.scss";

.billing_header {
  padding: 0 10%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: xx-large;
  width: 100%;

  h2{
    color: #69cbe4;
  }
}

.redText {
  color: red;
}

.monthContent {
  display: flex;
  flex-wrap: wrap;
  padding-left: 2%;
  padding-right: 2%;
}

.monthTitle {
  margin-bottom: 2%;
  display: flex;
  flex-wrap: wrap;
  padding-left: 2%;
  padding-right: 2%;
}

.print {
  margin-left: 2%;
}

/*.print #print{
    text-transform: none;
    background-color: #2461ca;
    color: white;
}

.print #print:hover{
    text-transform: none;
    background-color: #427ee6;
    color: white;
}*/

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 1em;
}
