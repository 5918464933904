.title {
  position: sticky;
  top: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $pink;
  color: $background-color;
  height: 5rem;
}

.deleteA {
  fill: none;
  stroke: $pink;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 3px;
}

.printIconSvg {
  width: 40px;
  height: 40px;
  color: $pink;
}

.addCircle {
  color: $dark-purple;
}
.addPath {
  color: white;
}

.editIconSvg {
  fill: none;
  stroke: $dark-purple;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 3px;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}

#form-dialog-title {
  display: flex;
  flex-wrap: wrap;
  padding-left: 2%;
  padding-right: 2%;
  background-color: $pink;
  color: $background-color;
  align-items: center;
  justify-content: center;

  flex: 1 100%;
}

.MuiAccordionDetails-root {
  padding: 8px 0px 10px !important;
}