@import "../../const.scss";
@import "../../general.scss";

.defaultContainer {
  width: 100%;
  padding-left: 0;
}

.MuiTableBody-root {
  color: $dark-purple;
}

.patientTable {
  max-width:  "100%";
}
