@import "../const.scss";

.bm-burger-button {
  position: fixed;
  width: 27pt;
  height: 18pt;
  margin-left: 2%;
  margin-top: 26px;
}

.sidebar {
  padding-left: 10pt;
  padding-top: 50pt;
  background: $dark-purple;
  height: 100%;
}

.menu-item {
  color: $background-color;
  font-size: 15pt;
  font-family: "Segoe UI";
  display: block;
  height: 58px;

  span {
    margin-left: 10px;
  }
}

a:hover {
  color: $blue;
  text-decoration: none;
}

.menu_image {
  display: inline-block;
  text-align: center;
  width: 20px;
  height: 20px;
}
