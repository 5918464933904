@font-face {
    font-family: "Segoe UI";
    src: local("Segoe UI") url("/resources/fonts/Segoe UI/Segoe UI.woff") format("woff"),
}
@font-face {
    font-family: "Segoe UI SemiBold";
    src: local("Segoe UI SemiBold") url("/resources/fonts/Segoe UI/Segoe UI Bold.woff") format("woff"),
}



/* Colors */
$background-color: #ffffff;
$pink: #FFD1B2;
$grey: #6B6F8F21;
$blue: #69CBE4;
$dark-purple: #6B6F8F;
$orange: #F69A54;
  
:export {
    darkPurple: $dark-purple;
}