@import "../../const.scss";
@import "../../general.scss";

.settings {
  .MuiContainer-root {
    margin-top: 5em;
    padding: 5em;
    box-shadow: 0 0 3px grey;
    display: flex;
    justify-content: center;
  }
}

.buttonContainer {
  margin-top: 2em;
  width: 100%;
  display: flex;
  justify-content: space-around;
}
