.dateContainer {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.beginDateContainer {
  display: flex;
  flex-direction: column;
  min-height: 356px;
  justify-content: flex-end;
  margin-bottom: 1rem;
}

.dateEndContainer {
  display: flex;
  flex-flow: column wrap;
  min-width: 350px;
  margin-bottom: 16px;
}

.dateEndCalendar {
  margin-top: 16px;
}

.dailyFrequency {
  display: flex;
  flex-flow: row wrap;
}

.frequencyContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.MuiDialog-paperWidthSm {
  min-width: 900px;
}

.MuiSwitch-colorPrimary {
  color: #7c9dd6;
}

.suspensionCalendarContainer {
  display: flex;
  justify-content: center;
}
